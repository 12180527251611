import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import Form  from "../section-components/form";
import Map  from "../contact-component/map";

const ContactPage = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div className="contact-list pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div className="contact-list-inner">
                <div className="media">
                  <div className="media-left">
                  <i className="fa fa-map text-white" style={{fontSize:"30px"}}></i>
                  </div>
                  <div className="media-body align-self-center">
                    <h5>Location</h5>
                    <p>{rpdata?.dbPrincipal?.location[0].address}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-list-inner">
                <div className="media">
                  <div className="media-left">
                  <i className="fa fa-phone text-white" style={{fontSize:"25px"}}></i>
                  </div>
                  <div className="media-body align-self-center">
                    <h5>Our Phone</h5>
                    {rpdata?.dbPrincipal?.phones?.slice(0,1).map((phone, index) => {
                      return (
                        <p key={index}>
                          <a href={`tel:+1${phone.phone}`}>{phone.phone}</a>
                        </p>
                      );
                    })}
                    {rpdata?.dbPrincipal?.phones?.slice(1.1).map((phone, index) => {
                      return (
                        <p key={index}>
                          <a href={`tel:+1${phone.phone}`}>{phone.phone}</a>
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact-list-inner">
                <div className="media">
                  <div className="media-left">
                    <i className="fa fa-calendar text-white" style={{fontSize:"25px"}}></i>
                  </div>
                  <div className="media-body align-self-center">
                    <h5>{rpdata?.dbPrincipal?.workdays[0].day}</h5>
                    <p>{rpdata?.dbPrincipal?.workHours[0].hour}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* counter area start */}
      <div className="counter-area pd-bottom-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="section-title mb-0">
                <h6 className="sub-title right-line">Get in touch</h6>
                <h2 className="title">Write Us a Message</h2>
                <p className="content pb-3">{rpdata?.dbValues?.[0].description}</p>
                <ul className="social-media style-base pt-3">
                  {rpdata?.dbSocialMedia?.redes.map((red, index) => {
                    return (
                      <li key={index}>
                        <a href={red.url} target="_blank">
                          <i
                            className={`fa fa-${red.icon}`}
                            aria-hidden="true"
                          />
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-lg-8 mt-5 mt-lg-0">
            <div class="elfsight-app-465d5eae-f8ab-4f86-887a-761511165a70"></div>
            </div>
          </div>
        </div>
      </div>
      {/* counter area end */}
      {/* contact area start */}
      <Map/>
    </div>
  );
};

export default ContactPage;
